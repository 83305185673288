import React from "react";
import Styles from "./menus.module.css";
import MenuData from "./menudata";
import { Link } from "react-router-dom";

const Menus = () => {
  return (
    <div className={Styles.container}>
      <div>
        {MenuData.map((menu) => (
          <Link to={menu.link} key={menu.id}>
            <li >{menu.name}</li>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Menus;
