import React from "react";
import Styles from "./footer.module.css";
import Logo from "../../images/logo.png";
import Call from "../../images/phone.png";
import CreativeDesignsData from "./creativedata";
import OfficeStationeryData from "./officestationerydata";
import PromotionalItemsData from "./promotionalitemsdata";
import SignageData from "./signagedata";
import CopyRightData from "./copyright";

const Footer = () => {
  return (
    <div>
      <div className={Styles.container}>
        <div className={Styles.columnA}>
          <div className={Styles.callContainer}>
            <div className={Styles.imageContainer}>
              <img
                src={Logo}
                alt="Aspire Graphics Ltd"
                className={Styles.image}
              />
            </div>
            <div className={Styles.call}>
              <div className={Styles.imageContainerCall}>
                <img
                  src={Call}
                  alt="Aspire Graphics Ltd"
                  className={Styles.imageCall}
                />
              </div>
              <h3>
                <a href="tel:+254722513085">+254-722-513-085</a>{" "}
              </h3>
            </div>
          </div>
          <hr />
          <div className={Styles.emailEnquiries}>
            <div>
              <h4>General Enquiries</h4>
              <p>
                <a href="mailto:info@aspiregraphics.co.ke">
                  info@aspiregraphics.co.ke
                </a>
              </p>
            </div>
            <div>
              <h4>Sales</h4>
              <p>
                <a href="mailto:sales@aspiregraphics.co.ke">
                  sales@aspiregraphics.co.ke
                </a>
              </p>
            </div>
          </div>
          <hr />
        </div>
        <div className={Styles.columnB}>
          <div className={Styles.grid}>
            <div className={Styles.card}>
              <h4>Creative Designs</h4>
              {CreativeDesignsData.map((item) => (
                <li key={item.id}>{item.name}</li>
              ))}
            </div>
            <div className={Styles.card}>
              <h4>Office Stationery</h4>
              {OfficeStationeryData.map((item) => (
                <li key={item.id}>{item.name}</li>
              ))}
            </div>
            <div className={Styles.card}>
              <h4>Promotional Items</h4>
              {PromotionalItemsData.map((item) => (
                <li key={item.id}>{item.name}</li>
              ))}
            </div>

            <div className={Styles.card}>
              <h4>Adverts & Signage</h4>
              {SignageData.map((item) => (
                <li key={item.id}>{item.name}</li>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className={Styles.copyright}>
        {CopyRightData.map((item) => (
          <li key={item.id}>{item.name}</li>
        ))}
      </div>
    </div>
  );
};

export default Footer;
