const OfficeStaioneryData = [
  {
    id: "001",
    name: "Business Cards",
    description: "Pocket Size, Paper Business Cards",
    size: "8.5 X 5.5",
    weight: "300 Gsm",
    material: "Paper Matte/Gloss/Ivory",
    price: 10,
    design: 500,
    minimum: 100,
    print: "Color/Black",
    printType: "Double Sided/Single Sided",
    image: "/images/stationery/businesscards.png",
    finish: "Gloss/Matte",
    link: "/businesscards",
  },
  {
    id: "002",
    name: "Staff ID Cards",
    description: "Pocket Size, Magnetic, RFID Cards, 128K",
    size: "9.0 X 5.5",
    weight: "300 Gsm",
    material: "PVC",
    price: 450,
    design: 500,
    minimum: 10,
    print: "Color/Black",
    printType: "Double Sided/Single Sided",
    finish: "Gloss/Matte",
    image: "/images/stationery/staffidcards.png",
    link: "/staffid",
  },
  {
    id: "003",
    name: "Brochures",
    description: "3 Fold Business Brochure",
    size: "A4 Size",
    weight: "130,150/175/200 {Gsm}",
    material: "Paper",
    price: 60,
    design: 1500,
    minimum: 100,
    print: "Color/Black",
    printType: "Double Sided/Single Sided",
    finish: "Gloss/Matte",
    image: "/images/stationery/brochure.png",
    link: "/brochure",
  },
  {
    id: "004",
    name: "Flyers",
    description: "Single Leaf, Advertising ",
    size: "A4/A5/A6/A7",
    weight: "130,150/175/200 {Gsm}",
    material: "Paper",
    price: 10,
    design: 500,
    minimum: 100,
    print: "Color/Black",
    printType: "Double Sided/Single Sided",
    finish: "Gloss/Matte",
    image: "/images/stationery/flyer.png",
    link: "/flyer",
  },
];

export default OfficeStaioneryData;
