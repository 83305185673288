const MenuData = [
  {
    id: "001",
    name: "Graphics Design",
    link: "/graphicsdesign",
  },
  {
    id: "002",
    name: "Office Stationery",
    link: "/officestationery",
  },
  {
    id: "003",
    name: "Promotional Items",
    link: "/promotionalitems",
  },
  {
    id: "004",
    name: "Large Format",
    link: "/largeformat",
  },
  {
    id: "005",
    name: "Binding",
    link: "/binding",
  },
  {
    id: "006",
    name: "Blog",
    link: "/blog",
  },
  {
    id: "007",
    name: "FAQs",
    link: "/faqs",
  },
  {
    id: "008",
    name: "Contacts",
    link: "/contacts",
  },
];

export default MenuData;
