const PromotionalItemsData = [
  {
    id: 1,
    name: "T-Shirts",
    link: "/logos",
  },
  {
    id: 2,
    name: "Hoodies",
    link: "/logos",
  },
  {
    id: 3,
    name: "Tote Bags",
    link: "/logos",
  },
  {
    id: 4,
    name: "Pens",
    link: "/logos",
  },
  {
    id: 5,
    name: "Mugs",
    link: "/logos",
  },
  {
    id: 6,
    name: "Drink-Ware",
    link: "/logos",
  },
];
export default PromotionalItemsData;
