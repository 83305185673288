import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./components/home";
import Layout from "./components/Layout";
import Nomatch from "./components/nomatch";
function AppRouter() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="*" element={<Nomatch />} />
        </Route>
      
      </Routes>
    </>
  );
}

export default AppRouter;
