import React from "react";
import Styles from "./slider.module.css";
import Slider1 from "../../images/slider1.png";

const Slider = () => {
  return (
    <div>
      <div className={Styles.imageContainer}>
        <img src={Slider1} alt="ASP" className={Styles.image} />
      </div>
    </div>
  );
};

export default Slider;
