import Slider from "../slider/Slider";
import OfficeStatationery from "../products/stationery/officestationery";
import PromotionalSupplies from "../products/promotionalitems/promotionalsupplies";
import CreativeDesign from "../creativeDesigns/creativedesigns";
import Signage from "../products/signage/signage";
import DigitalAds from "../digitalads/digitalads";
import Binding from "../products/binding/binding";
import WebDesign from "../webdesign/webdesign";

export default function Home() {
  return (
    <>
      <div>
        <Slider />
        <OfficeStatationery />
        <PromotionalSupplies />
        <CreativeDesign />
        <Signage />
        <DigitalAds />
        <Binding />
        <WebDesign />
      </div>
    </>
  );
}
