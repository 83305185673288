import React from "react";
import Styles from "../creativeDesigns/creativedesigns.module.css";
import Featured from "../../images/digitalads.png";
const DigitalAds = () => {
  return (
    <div className={Styles.container}>
      <div className={Styles.imageContainer}>
        <img
          src={Featured}
          alt="Aspire Graphics Ltd"
          className={Styles.image}
        />
      </div>
      <div className={Styles.moreDataContainer}>
        <button className={Styles.moreDataButton1}>Request Service</button>
      </div>
    </div>
  );
};

export default DigitalAds;
