import React from "react";
import Styles from "./Navbar.module.css";
import Logo from "../../images/logo.png";
import WhatsApp from "../../images/whatsapp.png";
import Phone from "../../images/phone.png";
import Categories from "./navCategories";

const NavBar = () => {
  return (
    <div className={Styles.mainContainer}>
      <div className={Styles.leftContainer}>
        {" "}
        <div className={Styles.imageContainer}>
          <img
            src={Logo}
            alt="Aspire Graphics Ltd"
            className={Styles.image}
          />
        </div>
        <div className={Styles.callLeftContainer}>
          <h4 style={{ padding: "0rem 1rem", cursor: "pointer" }}>
            <a href="tel:+254722513085">+254-722-513-085</a>
          </h4>
        </div>
      </div>
      <div className={Styles.centerContainer}>
        <form className={Styles.formSearch}>
          <input
            className={Styles.inputSearch}
            type="search"
            placeholder="Search"
          />
          <select className={Styles.button}>
            {Categories.map((item) => (
              <option key={item.id} value={item.id}>
                {item.category}
              </option>
            ))}
          </select>
        </form>
      </div>
      <div className={Styles.rightContainer}>
        <div className={Styles.imageContainerWhatsApp}>
          <img
            src={Phone}
            alt="Aspire Graphics Ltd"
            className={Styles.imageWhatsApp}
          />
        </div>
        <p style={{ padding: "0rem 1rem", cursor: "pointer" }}>
          <a href="tel:+254722513085">+254-722-513-085</a>
        </p>
        <div className={Styles.imageContainerWhatsApp}>
          <img
            src={WhatsApp}
            alt="Aspire Graphics Ltd"
            className={Styles.imageWhatsApp}
          />
        </div>
      </div>
    </div>
  );
};

export default NavBar;
