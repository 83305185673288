import React from "react";
import Styles from "./promotionalitems.module.css";
import FeaturedImage1 from "../../../images/hoodie.jpg";
import PromotionalSuppliesLinks from "./datalinks";
import PromotionalSuppliesData from "./promotionaldata";

const PromotionalSupplies = () => {
  return (
    <div className={Styles.container}>
      <div className={Styles.sectionContainer}>
        <div className={Styles.columnLeft}>
          <div className={Styles.featuredTitle}>
            <p>Custom Branded Hoodies</p>
          </div>
          <div className={Styles.imageContainer}>
            <img
              src={FeaturedImage1}
              alt="Aspire Graphics Ltd"
              className={Styles.imageFeatured}
            />
          </div>
          <div className={Styles.placeOrder}>
            <p>Place Order</p>
          </div>
        </div>
        <div className={Styles.columnRight}>
          <div>
            <h2>Promotional Items</h2>
            <div className={Styles.grid}>
              {PromotionalSuppliesData.map((item) => (
                <div key={item.id} className={Styles.card}>
                  <div className={Styles.cardTitle}>
                    <p>{item.name}</p>
                  </div>
                  <div
                    style={{
                      textAlign: "center",
                    }}
                  >
                    <div className={Styles.imageContainerProduct}>
                      <img
                        src={item.image}
                        alt="Aspire Graphics Ltd"
                        className={Styles.imageProducts}
                      />
                    </div>
                  </div>

                  <div className={Styles.cardTitle}>
                    <p>Place Order</p>
                  </div>
                </div>
              ))}
            </div>
            <div className={Styles.textLinks}>
              {PromotionalSuppliesLinks.sort((a, b) => a - b).map((link) => (
                <li key={link.id}>{link.name} |</li>
              ))}

              <button className={Styles.moreDataButton}>More items</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PromotionalSupplies;
