const SignageData = [
  {
    id: 1,
    name: "Banners",
    link: "/logos",
  },
  {
    id: 2,
    name: "Stickers",
    link: "/logos",
  },
  {
    id: 3,
    name: "Roll-Up Banners",
    link: "/logos",
  },
  {
    id: 4,
    name: "Tear Drop",
    link: "/logos",
  },
  {
    id: 5,
    name: "Back-Drop",
    link: "/logos",
  },
  {
    id: 6,
    name: "Mobile Apps",
    link: "/logos",
  },
];
export default SignageData;
