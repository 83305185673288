import React from "react";
import Slider from "../slider/Slider1";

function NoMatch() {
  return (
    <div>
      <Slider />
    </div>
  );
}

export default NoMatch;
