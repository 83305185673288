const SignageData = [
  {
    id: "001",
    name: "Caps",
    description: "Branded Basesball caps",
    size: "small, large, Xl",
    weight: "0.5g",
    material: "cotton",
    price: 500,
    design: 500,
    minimum: 1,
    print: "Color/Black",
    printType: "Heat Press",
    image: "/images/promotional/baseballcaps.png",
    finish: "Gloss/Matte",
    link: "/businesscards",
  },
  {
    id: "002",
    name: "Coffe Mugs",
    description: "Custom Branded Coffee Mugs",
    size: "350ml",
    weight: "0.5g",
    material: "ceramic",
    price: 500,
    design: 500,
    minimum: 1,
    print: "Color/Black",
    printType: "Heat Press",
    image: "/images/promotional/mugs.png",
    finish: "Gloss/Matte",
    link: "/businesscards",
  },
  {
    id: "003",
    name: "Branded T-Shirts",
    description: "Custom Branded T-Shirts",
    size: "small, large, Xl",
    weight: "0.5g",
    material: "cotton",
    price: 500,
    design: 500,
    minimum: 1,
    print: "Color/Black",
    printType: "Heat Press",
    image: "/images/promotional/tshirts.png",
    finish: "Gloss/Matte",
    link: "/businesscards",
  },
  {
    id: "004",
    name: "Branded Hoodies",
    description: "Custom Branded Hoodies",
    size: "small, large, Xl",
    weight: "0.5g",
    material: "cotton",
    price: 500,
    design: 500,
    minimum: 1,
    print: "Color/Black",
    printType: "Heat Press",
    image: "/images/promotional/hoodies.png",
    finish: "Gloss/Matte",
    link: "/businesscards",
  },
];

export default SignageData;
