const CopyRightData = [
  {
    id: 1,
    name: "© Aspire Graphics 2023",
    link: "aspire",
  },
  {
    id: 2,
    name: "Terms & Conditions",
    link: "termsandconditions",
  },
  {
    id: 3,
    name: "Privacy",
    link: "privacy",
  },
  {
    id: 4,
    name: "About Us",
    link: "aboutus",
  },
];

export default CopyRightData;
