const Categories = [
  {
    id: "001",
    category: "Office Stationery",
    description:
      "Business Cards, Staff ID Cards, Name Tags, Table Tags, Event Cards, Letter Heads, Brochures, flyers, Posters, Catalogues, Documents, Funeral Programs, Studio Photos, Mounted Photos, Note Books,Receipt Books,Invoice Books,Award Certificates",
  },
  {
    id: "002",
    category: "Promotional Items",
    description:
      "Baseball Caps, Gift Mugs, Custom Tshirts, Branded Hoodies, Event Cards, Custom Blouses, Jumpers, Polo Shirts, Office Shirts, Dust Coats, Aprons, Key Holders, Bandanas, Denim Branding, Wood Engraving, Handkerchiefs, Fleece Jackets, Carry Bags, Umbrellas, Gift Bags, ",
  },
  {
    id: "003",
    category: "Large Format",
    description:
      "Stickers, Banner, Office Branding, Signage, Window Graphics, 3D Blocks ",
  },
  {
    id: "004",
    category: "Binding",
    description: "Spiral, Perfect,O-Wire",
  },
];

export default Categories;
