import React from "react";
import Styles from "./webdesign.module.css";
import Featured from "../../images/webdesign.png";
const WebDesign = () => {
  return (
    <div className={Styles.container}>
      <div className={Styles.columnA}>
        <div className={Styles.imageContainer}>
          <img
            src={Featured}
            alt="Aspire Graphics Ltd"
            className={Styles.image}
          />
        </div>
      </div>
      <div className={Styles.columnB}>
        <h2>WebSite & Mobile App Designs Mock Up Pages</h2>
        <p>
          We help businesses create stunning and user-friendly websites and
          mobile apps.
        </p>
        <p>
          Whether it's a Website Landing pages or E-commerce platforms, Or
          mobile App to drive and support your business, or Product Mock-up
          designs we can help you validate your goals.
        </p>
        <button className={Styles.moreDataButton}>Request Service</button>
      </div>
    </div>
  );
};

export default WebDesign;
