const CreativeDesignsData = [
  {
    id: 1,
    name: "Logos",
    link: "/logos",
  },
  {
    id: 2,
    name: "Flyers & Posters",
    link: "/logos",
  },
  {
    id: 3,
    name: "Info-Graphics",
    link: "/logos",
  },
  {
    id: 4,
    name: "Business Reports",
    link: "/logos",
  },
  {
    id: 5,
    name: "Websites",
    link: "/logos",
  },
  {
    id: 6,
    name: "Mobile Apps",
    link: "/logos",
  },
];

export default CreativeDesignsData;
