const BindingLinks = [
  {
    id: 1,
    name: "Baseball Caps",
    link: "caps",
  },
  {
    id: 2,
    name: "Gift Mugs",
    link: "mugs",
  },
  {
    id: 3,
    name: "Custom Tshirts",
    link: "tshirts",
  },
  {
    id: 4,
    name: "Branded Hoodies",
    link: "Hoodies",
  },
  {
    id: 5,
    name: "Event Cards",
    link: "eventcards",
  },
  {
    id: 6,
    name: "Custom Blouses",
    link: "blouses",
  },
  {
    id: 7,
    name: "Jumpers",
    link: "jumpers",
  },
  {
    id: 8,
    name: "Polo Shirts",
    link: "poloshirts",
  },
  {
    id: 9,
    name: "Office Shirts",
    link: "officeshirts",
  },
  {
    id: 10,
    name: "Dust Coats",
    link: "dustcoats",
  },
  {
    id: 11,
    name: "Aprons",
    link: "Aprons",
  },
  {
    id: 12,
    name: "Key Holders",
    link: "keyholders",
  },
  {
    id: 13,
    name: "Bandanas",
    link: "Bandana",
  },
  {
    id: 14,
    name: "Denim Branding",
    link: "denim",
  },
  {
    id: 15,
    name: "Wood Engraving",
    link: "woodengraving",
  },
  {
    id: 16,
    name: "Handkerchiefs",
    link: "handkerchiefs",
  },
  {
    id: 17,
    name: "Fleece Jackets",
    link: "fleecejackets",
  },
  {
    id: 18,
    name: "Carry Bags",
    link: "carrybags",
  },
  {
    id: 19,
    name: "Umbrellas",
    link: "umbrellas",
  },
  {
    id: 20,
    name: "Gift Bags",
    link: "giftbags",
  },
];

export default BindingLinks;
