import Footer from "./footer/footer";
import Menus from "./menus/menus";
import NavBar from "./navbar/navbar";
import { Outlet } from "react-router-dom";

const Layout = () => {
  return (
    <div>
      <NavBar />
      <Menus />
      {/* An <Outlet> renders whatever child route is currently active,
          so you can think about this <Outlet> as a placeholder for
          the child routes we defined above. */}
          <Outlet />
      <Footer />
    </div>
  );
};

export default Layout;
