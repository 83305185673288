const OfficeStationeryData = [
  {
    id: 1,
    name: "Business Cards",
    link: "/logos",
  },
  {
    id: 2,
    name: "Letter Heads",
    link: "/logos",
  },
  {
    id: 3,
    name: "Staff ID Cards",
    link: "/logos",
  },
  {
    id: 4,
    name: "Receipt Books",
    link: "/logos",
  },
  {
    id: 5,
    name: "Event Cards",
    link: "/logos",
  },
  {
    id: 6,
    name: "Calendars",
    link: "/logos",
  },
];
export default OfficeStationeryData;
