const OfficeStationeryLinks = [
  {
    id: 1,
    name: "Business Cards",
    link: "businesscards",
  },
  {
    id: 2,
    name: "Staff ID Cards",
    link: "staffidcards",
  },
  {
    id: 3,
    name: "Name Tags",
    link: "staffidcards",
  },
  {
    id: 4,
    name: "Table Tags",
    link: "staffidcards",
  },
  {
    id: 5,
    name: "Event Cards",
    link: "eventcards",
  },
  {
    id: 6,
    name: "Letter Heads",
    link: "letterheads",
  },
  {
    id: 7,
    name: "Brochures",
    link: "brochures",
  },
  {
    id: 8,
    name: "flyers",
    link: "flyers",
  },
  {
    id: 9,
    name: "Posters",
    link: "Posters",
  },
  {
    id: 10,
    name: "Catalogues",
    link: "catalogues",
  },
  {
    id: 11,
    name: "Documents",
    link: "documents",
  },
  {
    id: 12,
    name: "Funeral Programs",
    link: "funeralprograms",
  },
  {
    id: 13,
    name: "Studio Photos",
    link: "Studio Photos",
  },
  {
    id: 14,
    name: "Mounted Photos",
    link: "mountedphotos",
  },
  {
    id: 15,
    name: "Note Books",
    link: "notebooks",
  },
  {
    id: 16,
    name: "Receipt Books",
    link: "receiptbooks",
  },
  {
    id: 17,
    name: "Invoice Books",
    link: "invoicebooks",
  },
  {
    id: 18,
    name: "Award Certificates",
    link: "certificates",
  },
];

export default OfficeStationeryLinks;
